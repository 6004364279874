import React from 'react'
import YoutubeSvg from 'svg/youtube'
import Linkedin from '../../svg/linkedin'
import Behance from '../../svg/behance'
import Pinterest from '../../svg/pinterest'

const Links = () => {
	const socials = [
		{
			el: <YoutubeSvg />,
			link: 'https://www.youtube.com/@cuubstudio',
		},
		{
			el: <Linkedin />,
			link: 'https://www.linkedin.com/company/cuub-architectural-visualization-studio/',
		},
		{ el: <Behance />, link: 'https://www.behance.net/cuubstudio/' },
		{ el: <Pinterest />, link: 'https://ru.pinterest.com/cuubstudio/' },
	]

	return (
		<div className="flex min-h-screen flex-col">
			<div className="mx-auto max-w-screen-sm flex-grow px-8 sm:px-14">
				<div className="links mt-4">
					<div className="border-light-gray border-b transition-opacity duration-200 ease-in-out">
						<a
							className="accent-hover group flex justify-between py-6 font-headings text-2xl font-semibold leading-tight"
							href="https://www.cuubstudio.com/"
						>
							Website
							<i className="block h-6 w-6 -rotate-45 transition-transform duration-300 group-hover:rotate-0">
								<svg
									className="h-full w-full fill-current"
									viewBox="0 0 25 26"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="m12 25.728-1.756-1.756 9.656-9.655H.59v-2.634H19.9l-9.656-9.655L12 .272 24.728 13z"
										fillRule="evenodd"
									></path>
								</svg>
							</i>
						</a>
					</div>
					<div className="border-light-gray border-b transition-opacity duration-200 ease-in-out">
						<a
							className="accent-hover group flex justify-between py-6 font-headings text-2xl font-semibold leading-tight"
							href="https://www.cuubstudio.com/presentations/offer-for-new-clients-multilink/"
						>
							Special offer
							<i className="block h-6 w-6 -rotate-45 transition-transform duration-300 group-hover:rotate-0">
								<svg
									className="h-full w-full fill-current"
									viewBox="0 0 25 26"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="m12 25.728-1.756-1.756 9.656-9.655H.59v-2.634H19.9l-9.656-9.655L12 .272 24.728 13z"
										fillRule="evenodd"
									></path>
								</svg>
							</i>
						</a>
					</div>
					<div className="border-light-gray border-b transition-opacity duration-200 ease-in-out">
						<a
							className="accent-hover group flex justify-between py-6 font-headings text-2xl font-semibold leading-tight"
							href="https://www.cuubstudio.com/careers/"
						>
							Careers
							<i className="block h-6 w-6 -rotate-45 transition-transform duration-300 group-hover:rotate-0">
								<svg
									className="h-full w-full fill-current"
									viewBox="0 0 25 26"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="m12 25.728-1.756-1.756 9.656-9.655H.59v-2.634H19.9l-9.656-9.655L12 .272 24.728 13z"
										fillRule="evenodd"
									></path>
								</svg>
							</i>
						</a>
					</div>
				</div>
				<div
					id="hoomans"
					className="mt-14 flex flex-col justify-between gap-y-6 pb-14"
				>
					<div className="flex items-center gap-x-6 sm:items-stretch sm:gap-x-12">
						<img
							className="aspect-square w-32 flex-none sm:w-40"
							src="https://images.prismic.io/cuub/da6975b4-e216-43bf-81d0-3deead0faa08_CUUB-29.jpg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&q=75&w=320"
							alt="Bohdan Behmat"
						/>
						<div className="break-all">
							<p className="mt-4">Bohdan Behmat</p>
							<p className="opacity-25">Founder</p>
							<p className="mt-4 text-sm font-semibold sm:text-base sm:leading-none">
								<a
									className="accent-hover"
									href="mailto:bohdan@cuubstudio.com "
								>
									bohdan@cuubstudio.com
								</a>
							</p>
							<p className="mt-4 leading-none">
								<a
									href="https://www.linkedin.com/in/bohdan-behmat-564221248/"
									className="accent-hover group inline-block h-3.5 text-sm font-semibold leading-none"
									target="_blank"
									rel="noreferrer"
								>
									<i className="mr-4 inline-block h-3 w-3 bg-black/25 transition-colors duration-300 group-hover:bg-accent"></i>
									LinkedIn
								</a>
							</p>
						</div>
					</div>
					<div className="flex items-center gap-x-6 sm:items-stretch sm:gap-x-12">
						<img
							className="aspect-square w-32 flex-none sm:w-40"
							src="https://images.prismic.io/cuub/a3875a27-b6be-4885-b61f-d6a7d5fe6a24_alina-serious.jpg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&q=75&w=318"
							alt="Alina Kandibolotska"
						/>
						<div className="break-all">
							<p className="mt-4">Alina Kandibolotska</p>
							<p className="opacity-25">Chief Executive Officer</p>
							<p className="mt-4 text-sm font-semibold sm:text-base sm:leading-none">
								<a
									className="accent-hover"
									href="mailto:alina@cuubstudio.com"
								>
									alina@cuubstudio.com
								</a>
							</p>
							<p className="mt-4 leading-none">
								<a
									href="https://www.linkedin.com/in/alina-kandibolotska-377991229/"
									className="accent-hover group inline-block h-3.5 text-sm font-semibold leading-none"
									target="_blank"
									rel="noreferrer"
								>
									<i className="mr-4 inline-block h-3 w-3 bg-black/25 transition-colors duration-300 group-hover:bg-accent"></i>
									LinkedIn
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div id="socials" className="bg-black  text-white">
				<div className="d-flex mx-auto flex w-full max-w-screen-sm justify-between p-10 sm:p-14">
					{socials.map((el, index) => (
						<a
							className="accent-hover block w-12 sm:w-14"
							href={el.link}
							target="_blank"
							title="title"
							key={index}
							rel="noreferrer"
						>
							{el.el}
						</a>
					))}
				</div>
			</div>
		</div>
	)
}

export default Links
